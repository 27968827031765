var $x = function (id, win) {
    return (win || window).document.getElementById(id);
};
$x.isIE = function (ver) {
    var tr = /(msie\s*|trident.*rv:)([\d]+)/i.exec(navigator.userAgent);
    var r = tr ? { v: parseInt(tr[2]) } : false;
    return (r.v == ver || !ver) ? r : false;
};
$x.isV8 = function (win) {
    try { return win.location.host.toLowerCase().replace(/\:\d+/, "") == location.host.toLowerCase().replace(/\:\d+/, "") } catch (e) { return false; }
};
$x.top = function (wind) {
    //__isKYTop需要在General.js加载之前设置
    if ($x.topw) return $x.topw;
    var win = wind || window;
    while (!win.__isKYTop && win.parent && win.parent != win && $x.isV8(win.parent)) {
        win = win.parent;
    }
    if (win.__isKYTop) { return $x.topw = win; }
    while (win.dialogArguments != null) {
        win = $x.top(win.dialogArguments);
    }
    return $x.topw = win;
};
$x.appRoot = function () {
    if (!$x.appRootV) {
        var scripts = document.getElementsByTagName("script");
        for (var i = 0; i < scripts.length; i++) {
            var strJsPath = scripts[i].src.split('?')[0].toLowerCase();
            var pos = strJsPath.indexOf('/scripts/');
            if (pos > -1) {
                $x.appRootV = scripts[i].src.split('?')[0].substring(0, pos + 1);
                break;
            }
        }
        if ($x.appRootV.indexOf(":") == -1 && $x.appRootV.substr(0, 1) != "/") {
            $x.appRootV = $x.curPath() + $x.appRootV;
        }
    }
    return $x.appRootV;
};

$x.queryString = function (parname, url) {
    var re = new RegExp('(\\?|&)' + parname + "=([^\&]*)", "i");
    var urlParas = re.exec(url || location.search);
    return urlParas && urlParas[2];
};

document.write('<script src="' + $x.appRoot() + 'Scripts/Msg.aspx"></script>');

if ($x.isIE()) {
    document.write('<script src="' + $x.appRoot() + 'Scripts/PolyFill/ES6-promise.auto.min.js"></script>');
}